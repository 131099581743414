import { createSlice } from "@reduxjs/toolkit"

const globalSlice = createSlice({
  name: 'global',
  initialState: {
    fase: null,
    quota: {},
    siswaCols: null,
    checkUsulanLog: false
  },
  reducers: {
    setFase: (state, action) => {
      state.fase = action.payload
    },
    setQuota: (state, action) => {
      state.quota = action.payload
    },
    setSiswaCols: (state, action) => {
      state.siswaCols = action.payload
    },
    setCheckUsulanLog: (state, action) => {
      state.checkUsulanLog = action.payload
    }
  }
})

export const { setFase, setQuota, setSiswaCols, setCheckUsulanLog } = globalSlice.actions
export default globalSlice.reducer